import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import 'core-js/stable';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'

import React from 'react';
import ReactDOM from 'react-dom/client';
import './style.css';
import reportWebVitals from './reportWebVitals';

import {
    createBrowserRouter,
    RouterProvider
  } from "react-router-dom";

import LoadingScreen from './pages/LoadingScreen';
import Layout from './Layout';
import StartScreen from './pages/StartScreen';
import OrderType from './pages/OrderType';
import Menu from './pages/Menu';
import Cart from './pages/Cart';
import Payment from './pages/Payment';
import Confirmed from './pages/Confirmed';

const router = createBrowserRouter([
    {
        path: "/",
        element: <LoadingScreen />,
    },
    {
        path: "/start",
        element: <StartScreen />,
    },
    {
        path: "/order-type",
        element: <OrderType />
    },
    {
        path: "/menu",
        element: <Menu />
    }, 
    {
        path: "/cart",
        element: <Cart />
    },
    {
        path: "/payment",
        element: <Payment />
    }, 
    {
        path: "/confirmed",
        element: <Confirmed />
    }
]);

const root = ReactDOM.createRoot(document.getElementById('root')  as HTMLElement);
root.render(
    <React.StrictMode>
        <Layout>
            <RouterProvider router={router} />
        </Layout>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
