import { FC } from 'react';

export const BackButton: FC<{onClick: Function}> = ({ onClick }) => {
    return (
        <button
            className="btn btn-small-spacing btn-body"
            onClick={() => {
                onClick()
            }}
        >
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-2">
                <path d="M18.6893 24L10.667 15.9777L18.6893 7.95567L20.267 9.53334L13.8227 15.9777L20.267 22.4223L18.6893 24Z" fill="currentColor"/>
            </svg> 
            Back
        </button>
    );
};

export default BackButton;

