import { FC, useState } from 'react';
import CancelModal from './Modals/CancelModal';

export const CancelButton: FC = () => {
    const [showCancelModal, setShowCancelModal] = useState(false);

    return (
        <>
            <button 
                className="btn btn-small-spacing btn-danger w-full"
                onClick={() => {
                    setShowCancelModal(true);
                }}
            >
                Cancel
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" className='ml-2'>
                    <path d="M8.53341 25.3333L6.66675 23.4666L14.1334 16L6.66675 8.53329L8.53341 6.66663L16.0001 14.1333L23.4667 6.66663L25.3334 8.53329L17.8667 16L25.3334 23.4666L23.4667 25.3333L16.0001 17.8666L8.53341 25.3333Z" fill="white"/>
                </svg>
            </button>

            <CancelModal 
                show={showCancelModal} 
                setShow={setShowCancelModal}
            />
        </>
    );
};

export default CancelButton;
