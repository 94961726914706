import { useState, useCallback, FC } from "react";
import { useProduct } from "../../context/Product";
import { PurchaseOptionValue } from "../../types/types";

interface ProductCheckboxInterface {
    checkData: PurchaseOptionValue;
    selectedOption: any;
    maxOptionLimit: number; // Max number of options to select
    groupQuantity: number;
    productOption: any;
}
 
export const ProductCheckbox: FC<ProductCheckboxInterface> = ({
    checkData,
    selectedOption,
    maxOptionLimit,
    groupQuantity,
    productOption
}) => {

    const {
        selectedCustomizabilityOptions,
        setSelectedOptions,
    } = useProduct();

    const maxLimit = Math.min(checkData.maxLimit || maxOptionLimit, maxOptionLimit);
    
    const [quantity, setQuantity] = useState(
        selectedCustomizabilityOptions.length ? selectedCustomizabilityOptions.filter((uid: string) => uid === checkData.uid).length : 0
    );
    const [isChecked, setIsChecked] = useState(quantity > 0);

    const updateCheck = useCallback((newQuantity: number) => {
        const selectedOptionsNew = selectedOption; // { selectedDEfault Object }

        setIsChecked(newQuantity > 0);
        setQuantity(newQuantity);

        // Update options
        selectedOptionsNew.value = selectedOptionsNew?.value.filter((uid: string) => uid !== checkData.uid);

        // Value is an array of uid's, it needed adding for every quantity added.
        for (let index = 0; index < newQuantity; index++) {
            selectedOptionsNew.value.push(checkData.uid);
        }

        selectedOptionsNew.totals[checkData.uid] = Number(checkData.pricePerUnit) * newQuantity;
        selectedOptionsNew.quantities[checkData.uid] = newQuantity;        

        console.log(selectedOptionsNew, "selectedOptionsNew");

        setSelectedOptions((prevState: any) => ({
            ...prevState,
            [productOption.uid]: selectedOptionsNew,
        }));
    }, [checkData, groupQuantity, maxOptionLimit, isChecked, selectedOption, setSelectedOptions]);

    // Build up checkboxes
    const options = [];

    for (let i = 0; i <= maxLimit; i++) {
        options.push(
            <option 
                key={i} 
                value={i} 
                disabled={i > maxOptionLimit - groupQuantity + quantity && quantity !== i}
            >
                {i}
            </option>
        );
    }

    return (
        <button
            data-test="checkboxInput"
            onClick={(e) => {
                e.preventDefault();
                
                if(1 > maxOptionLimit - groupQuantity + quantity) return;

                updateCheck(quantity > 0 ? 0 : 1);                
            }}
            className="w-full focus:outline-none py-5 px-5 flex justify-between items-center font-bold text-xl mt-px bg-menu"
            type="button"
        >
            {checkData.label}

            <div className="flex items-center" style={{ minHeight: '39px' }}>
                <div className="flex items-center">
                    <button
                        className="mr-3 w-[27px] h-[27px]"
                        onClick={(e) => {
                            e.stopPropagation();
                            if(quantity >= 1) {
                                updateCheck(quantity - 1);
                            }
                        }}
                    >
                        <svg width="34" height="35" viewBox="0 0 34 35" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-full h-full">
                        <path d="M17.0002 0.833313C7.80016 0.833313 0.333496 8.29998 0.333496 17.5C0.333496 26.7 7.80016 34.1666 17.0002 34.1666C26.2002 34.1666 33.6668 26.7 33.6668 17.5C33.6668 8.29998 26.2002 0.833313 17.0002 0.833313ZM25.3335 19.1666H8.66683V15.8333H25.3335V19.1666Z" fill="#94A3B8"/>
                        </svg>
                    </button>

                    <select
                        value={quantity}
                        onChange={(event) => updateCheck(Number(event.target.value))}
                        onClick={(event) => { event.stopPropagation() }}
                        className="px-2 py-1 outline-none"
                    >
                        {options}
                    </select>

                    <button
                        className="ml-3 w-[27px] h-[27px]"
                        onClick={(e) => {
                            e.stopPropagation();
                            if((quantity + 1) <= maxOptionLimit - groupQuantity + quantity) {
                                updateCheck(quantity + 1);
                            }
                        }}
                    >
                        <svg width="34" height="35" viewBox="0 0 34 35" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-full h-full">
                            <path d="M17.0002 0.833313C7.80016 0.833313 0.333496 8.29998 0.333496 17.5C0.333496 26.7 7.80016 34.1666 17.0002 34.1666C26.2002 34.1666 33.6668 26.7 33.6668 17.5C33.6668 8.29998 26.2002 0.833313 17.0002 0.833313ZM25.3335 19.1666H18.6668V25.8333H15.3335V19.1666H8.66683V15.8333H15.3335V9.16665H18.6668V15.8333H25.3335V19.1666Z" fill="#94A3B8"/>
                        </svg>
                    </button>
                </div>

                <p className="text-black text-xl m-0 mx-3">
                    {Number(checkData.pricePerUnit) > 0 ? '+' : null} {checkData.pricePerUnitFormatted}
                </p>

                {isChecked && (
                    <svg 
                        width="39" 
                        height="39" 
                        viewBox="0 0 39 39" 
                        fill="none" 
                        xmlns="http://www.w3.org/2000/svg" 
                        className="w-9"
                    >
                        <path d="M19.5 0.75C9.15 0.75 0.75 9.15 0.75 19.5C0.75 29.85 9.15 38.25 19.5 38.25C29.85 38.25 38.25 29.85 38.25 19.5C38.25 9.15 29.85 0.75 19.5 0.75ZM28.875 21.375H10.125V17.625H28.875V21.375Z" fill="#F87171"/>
                    </svg>
                )}
            </div>
        </button>
    );
};

export default ProductCheckbox;