import { fetcher } from "./fetcher";
import { ERROR_HANDLING } from '../queries/errorHandling';

function cleanup(data: any) {
    return data.data.stratus21Categories;
}

// interface CategoriesProductsInterface {
//     storeId: string,
//     orderType: string
// }

export default async function fetchCategoriesProducts() {
    let fetchCategoriesProductsQuery = `query GetCategories {
        stratus21Categories(
            storeId: "2", 
            orderType: "collection"
        ) {
            items {
                id
                name
                description
                products {
                    inStock
                    active
                    allergyLong
                    allergyShort
                    id
                    sku
                    imageThumbnail
                    longDescription
                    name
                    price
                    priceFormatted
                    productImages {
                        alt
                        src
                    }
                    purchaseOptions {
                        uid
                        id
                        type
                        maxLimit
                        required
                        name
                        label
                        values {
                            id
                            label
                            minLimit
                            maxLimit
                            pricePerUnit
                            pricePerUnitFormatted
                            uid
                            value
                        }
                    }
                    shortDescription
                }
                active
                status
            }
            ${ERROR_HANDLING}
        }
    }`

    return await fetcher(fetchCategoriesProductsQuery, cleanup);
}