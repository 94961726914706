import { FC } from "react";
import { useRestartKiosk } from "../../hooks/restartKioskSession";

import Modal from "../Modal";

interface CancelModalInterface {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CancelModal: FC<CancelModalInterface> = ({ show, setShow }) => {
    const {restartKiosk} = useRestartKiosk();
    
    return (
        <Modal
            show={show}
            setShow={setShow}
        >
            <p className='font-bold mb-8 text-center text-[29px]'>Are you sure you want to cancel your order?</p>
            <div className='flex'>
                <button 
                    className='btn btn-small-spacing btn-body mr-4 w-1/2' 
                    onClick={() => {
                        setShow(false);
                    }}
                >
                    No
                </button>

                <button 
                    className='btn btn-small-spacing btn-danger w-1/2'
                    onClick={restartKiosk}
                >
                    Yes
                </button>
            </div>
        </Modal>
    )
};

export default CancelModal;