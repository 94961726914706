import { useNavigate } from "react-router-dom";

import { MenuProvider } from "../context/Menu";

import { useGetTheme } from "../hooks/useGetTheme";
import { useGetCart } from "../hooks/useGetCart";

import CancelButton from "../components/CancelButton";
import Logo from "../components/Logo";
import HelpButton from "../components/HelpButton";
import BackButton from "../components/BackButton";
import TimeoutModal from "../components/Modals/TimeoutModal";
import MenuCategories from "../components/Menu/MenuCategories";
import MenuProductList from "../components/Menu/MenuProductList";
import MenuButtons from "../components/Menu/MenuButtons";
import MenuSearch from "../components/Menu/MenuSearch";

function Menu() {
    const navigate = useNavigate();
    const theme = useGetTheme();
    const cart = useGetCart();

    return (
        <MenuProvider>
            <div className="grid grid-cols-4 h-[1600px] relative">
                <div className="sidebar bg-white flex flex-col h-[1600px]">
                    <Logo />
                    <MenuCategories />

                    <div className="buttons flex flex-col mx-7 mb-8 mt-auto">
                        <div className="mb-4 flex flex-col">
                            <BackButton 
                                onClick={() => {
                                    navigate("/order-type");
                                }}
                            />
                        </div>
                        <div className="mb-4">
                            <CancelButton />
                        </div>
                        <HelpButton />
                    </div>
                </div>
                
                <div className="col-span-3 bg-menu pt-11 pb-0 h-[1600px] relative">
                    <div className={`${cart?.cart?.prices?.grand_total.value === 0 ? 'menu-scrollable-area' : 'menu-scrollable-area-with-total' } overflow-y-auto px-11`}>
                        <MenuSearch />

                        <div className="flex mb-11 overflow-x-auto">
                            <button
                                className="rounded-full py-4 px-8 text-lg font-bold leading-none mr-4 border break-keep"
                                style={{
                                    backgroundColor: theme.secondaryColor,
                                    color: theme.primaryColor
                                }}
                            >
                                All
                            </button>
                            <button
                                className="rounded-full py-4 px-8 text-lg font-bold leading-none mr-4 border border-slate-400 whitespace-nowrap"
                            >
                                TODO
                            </button>
                            <button
                                className="rounded-full py-4 px-8 text-lg font-bold leading-none mr-4 border border-slate-400 whitespace-nowrap"
                            >
                                Sandwiches
                            </button>
                            <button
                                className="rounded-full py-4 px-8 text-lg font-bold leading-none mr-4 border border-slate-400 whitespace-nowrap"
                            >
                                Vegetarian
                            </button>
                        </div>

                        <div className="menu-group mb-6">
                            <MenuProductList />
                        </div>
                    </div>

                    <MenuButtons />
                </div>
            </div>

            <TimeoutModal />
        </MenuProvider>
    )
}

export default Menu;