import { cartInfo } from '../queries/cart';
import { ERROR_HANDLING } from '../queries/errorHandling';
import { fetcher } from './fetcher';

// Clean up server response (It was returning data.data.cart.cart etc)
function responseCleanup(data: any) {
    return data.data.removeItemFromCart;
}

interface RemoveFromCartApiInterface {
    cartId: string;
    cartItemId: number;
}

export async function removeFromCart(payload : RemoveFromCartApiInterface) {
    return await fetcher(
        `
            mutation RemoveItemFromCart {
                removeItemFromCart(
                    input: {
                        cart_id: "${payload.cartId}", 
                        cart_item_id: "${payload.cartItemId}"
                    } 
                ) {
                    ${cartInfo}
                    ${ERROR_HANDLING}
                }
            }    
        `,
        responseCleanup
    );
}