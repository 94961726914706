import { FC, useEffect, useState } from "react"
import { useProduct } from "../../context/Product"
import ProductOption from "./ProductOption"

interface ProductInputsInterface {
    editData: any
}
 
export const ProductInputs: FC<ProductInputsInterface> = ({ 
    editData
}) => {

    const {
        productDetails,
        setSelectedCustomizabilityOptions
    } = useProduct();

    const productOptions = productDetails.purchaseOptions;

    useEffect(() => {
        // if (editData) {
        //     if (editData?.customizable_options) {
        //         const newArray = [];
        //         const enteredOptionsArray = [];

        //         editData.customizable_options.forEach(option => {
        //             option.values.forEach(value => {
        //                 if (value.label) {
        //                     newArray.push(value.customizable_option_value_uid);
        //                     return false;
        //                 } else {
        //                     // Must be textarea
        //                     enteredOptionsArray.push({
        //                         uid: value.customizable_option_value_uid,
        //                         value: value.value
        //                     });
        //                 }

        //             });

        //             return false;
        //         });

        //         // Radio & Checkboxes
        //         setSelectedCustomizabilityOptions(newArray);
        //         // Text Areas
        //         setSelectedTextAreaCustomizabilityOptions(enteredOptionsArray);

        //     }
        // }
    }, [editData, setSelectedCustomizabilityOptions]);

    // Set up default selected options for all product options
    // useEffect(() => {
    //     // Fetch data in selected options
    //     const currentData = selectedOptions || {};

    //     // Create default objects
    //     if (!Object.keys(currentData).length && productOptions.length) {
    //         let newObj:any = {};

    //         productOptions.map((productOption: { uid: string | number; type: any; }) => {

    //             // if(productOption.type === 'area') {
    //             //     return false;
    //             // }

    //             // Modify the selectedValues
    //             newObj[productOption.uid] = {
    //                 value: [],
    //                 type: productOption.type,
    //                 quantities: {},
    //                 totals: {}
    //             };

    //             return false;
    //         });

    //         setSelectedOptions((prevState: any) => ({
    //             ...prevState,
    //             ...newObj
    //         }));
    //     }

    // }, [productOptions, selectedOptions, setSelectedOptions]);

    if(!productDetails?.purchaseOptions || !productDetails?.inStock || !productOptions || !productOptions.length) return null;

    return(
        <>
            {productOptions.map((productOption) => (
                <ProductOption
                    key={productOption?.id}
                    productOption={productOption}
                />
            ))
            }
        </>
    ) 
}

export default ProductInputs;