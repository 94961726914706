
export const cartInfo = `
cart {
    email
    id
    total_quantity
    items {
        id
        prices {
            price {
                currency
                value
            }
            row_total {
                currency
                value
            }
            row_total_including_tax {
                currency
                value
            }
            total_item_discount {
                currency
                value
            }
        }
        product {
            id
            name
            description {
                html
            }
            image {
                url
            }
            price_range {
                maximum_price {
                    final_price {
                        currency
                        value
                    }
                    regular_price {
                        currency
                        value
                    }
                }
                minimum_price {
                    final_price {
                        currency
                        value
                    }
                    regular_price {
                        currency
                        value
                    }
                }
            }
        }
        ... on SimpleCartItem {
            customizable_options {
                id
                customizable_option_uid
                label
                values {
                    customizable_option_value_uid
                    value
                    label
                }
            }
        }
        quantity
    }
    prices {
        grand_total {
            currency
            value
        }
        subtotal_including_tax {
            currency
            value
        }
        subtotal_with_discount_excluding_tax {
            currency
            value
        }
        subtotal_excluding_tax {
            value
            currency
        }
    }
    isLoyaltyApplied
    appliedLoyaltyPoints
    hasRegisteredTCP
    appliedVouchers {
        description
        isValid
        voucherIdentifierRoleId
        voucherPercentValue
        voucherValue
    }
    available_payment_methods {
        code
        title
    }
    selected_payment_method {
        code
        title
    }
    orderType
    timeslot
    seating {
        label
        number
        row
        seatValue
    }
    applicableVouchers {
        description
        expiry
        isValid
        voucherIdentifierRoleId
        voucherPercentValue
        voucherValue
    }
    messages {
        message
        type
    }
}
`;