import { FC } from 'react';
import { useGetTheme } from '../hooks/useGetTheme';

export const Logo: FC = () => {
    const theme = useGetTheme();

    return (
        <div 
            className="p-10 mx-auto rounded-b-lg relative z-10 w-[175px]"
            style={{
                backgroundColor: theme.primaryColor
            }}
        >
            <img src={theme.logo} alt="Logo" className="w-24" />
        </div>
    );
};

export default Logo;

