import { FC, useState } from 'react';
import { useGetTheme } from '../hooks/useGetTheme';
import { HelpModal } from './Modals/HelpModal';

export const HelpButton: FC = () => {
    const [showHelpModal, setShowHelpModal] = useState(false);
    const theme = useGetTheme();

    return (
        <>
            <button 
                className="btn btn-small-spacing text-white"
                style={{
                    backgroundColor: theme.primaryColor
                }}
                onClick={() => {
                    setShowHelpModal(true);
                }}
            >
                Help
                <svg width="27" height="22" viewBox="0 0 27 22" fill="none" xmlns="http://www.w3.org/2000/svg" className='ml-2'>
                    <path d="M21.5001 12.3334V9.66671H26.8334V12.3334H21.5001ZM23.1001 21.6667L18.8334 18.4667L20.4334 16.3334L24.7001 19.5334L23.1001 21.6667ZM20.4334 5.66671L18.8334 3.53337L23.1001 0.333374L24.7001 2.46671L20.4334 5.66671ZM4.16675 20.3334V15H2.83341C2.10008 15 1.4723 14.7389 0.950081 14.2167C0.427859 13.6945 0.166748 13.0667 0.166748 12.3334V9.66671C0.166748 8.93337 0.427859 8.3056 0.950081 7.78337C1.4723 7.26115 2.10008 7.00004 2.83341 7.00004H8.16675L14.8334 3.00004V19L8.16675 15H6.83341V20.3334H4.16675ZM12.1667 14.2667V7.73337L8.90008 9.66671H2.83341V12.3334H8.90008L12.1667 14.2667ZM16.1667 15.4667V6.53337C16.7667 7.06671 17.2501 7.71671 17.6167 8.48337C17.9834 9.25004 18.1667 10.0889 18.1667 11C18.1667 11.9112 17.9834 12.75 17.6167 13.5167C17.2501 14.2834 16.7667 14.9334 16.1667 15.4667Z" fill="white"/>
                </svg>
            </button>

            <HelpModal 
                show={showHelpModal} 
                setShow={setShowHelpModal}
            />
        </>
    );
};

export default HelpButton;