import { fetcher } from "./fetcher";
import { ERROR_HANDLING } from '../queries/errorHandling';
import { cartInfo } from "../queries/cart";
import { getCartLocalStorage } from "../utilities/localStorage";

function cleanup(data: any) {
    return data.data.cart;
}

export default async function fetchCart() {
    const guestCartID = getCartLocalStorage();

    let fetchCartQuery = `query {
        cart(cart_id: "${guestCartID}") {
            ${cartInfo}
            ${ERROR_HANDLING}
        }
    }`

    return await fetcher(fetchCartQuery, cleanup);
}