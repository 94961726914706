import { FC } from "react";
import { useGetCart } from "../../hooks/useGetCart";

export const Totals: FC = () => {
    const cart = useGetCart();

    // TODO test this after integration

    return (
        <>
            <div className="py-5 px-8 mb-4 flex justify-between rounded-lg items-center bg-white">
                <p className="text-4xl"><strong>Subtotal</strong></p>
                <p className="text-4xl"><strong>£{cart?.cart.prices?.subtotal_including_tax?.value.toFixed(2) || '0.00'}</strong></p>
            </div>

            {cart?.cart.isLoyaltyApplied || (cart?.cart.appliedVouchers && cart?.cart.appliedVouchers.length) ? (
                    <div className="py-5 px-8 mb-4 flex flex-col rounded-lg bg-white w-full">
                        <p className="text-4xl mb-4"><strong>Discounts</strong></p>

                        {cart?.cart?.isLoyaltyApplied ? (
                            <div className="flex w-full justify-between items-center mb-4">
                                <strong>Reward Discount</strong>
                                <div className="flex mt-3">
                                    Reward Points (-{(Number(cart?.cart?.appliedLoyaltyPoints) * 100).toFixed()}pts)

                                    <div className="ml-auto text-green-800">
                                        -£{cart?.cart?.appliedLoyaltyPoints ? (Number(cart?.cart?.appliedLoyaltyPoints)).toFixed(2) : 0.00}
                                    </div>
                                </div>
                            </div>
                        ) : null}

                        {cart?.cart?.appliedVouchers && cart?.cart?.appliedVouchers.length ? (
                            <div className="flex w-full justify-between items-center mb-4">
                                <strong>Voucher Discounts</strong>
                                {cart?.cart?.appliedVouchers.map((voucher, index) => {
                                    return (
                                        <div className="flex mt-3" key={`appliedVoucher-${index}`}>
                                            {voucher?.description}
                                            <div className="ml-auto text-green-800">-£{parseFloat(voucher?.voucherValue).toFixed(2)}</div>
                                        </div>
                                    );
                                })}
                            </div>
                        ) : null}
                    </div>
                ) : null}

            <div className="py-5 px-8 mb-4 flex flex-col rounded-lg bg-white w-full">
                <p className="text-4xl mb-4"><strong>Discounts - TODO</strong></p>

                <div className="flex w-full justify-between items-center mb-4">
                    <p>£1.00 off any breakfast item</p>

                    <div className="flex items-center">
                        <p className="mr-5"><strong>-£1.00</strong></p>
                        <button 
                            className="p-1 rounded-md btn-danger"

                        >
                            <svg width="45" height="46" viewBox="0 0 45 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_2731_859)">
                                <path d="M35.625 12.5187L32.9812 9.875L22.5 20.3562L12.0187 9.875L9.375 12.5187L19.8562 23L9.375 33.4812L12.0187 36.125L22.5 25.6437L32.9812 36.125L35.625 33.4812L25.1437 23L35.625 12.5187Z" fill="white"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_2731_859">
                                <rect width="45" height="45" fill="white" transform="translate(0 0.5)"/>
                                </clipPath>
                                </defs>
                            </svg>
                        </button>
                    </div>
                </div>

                <div className="flex w-full justify-between items-center">
                    <p>£1.00 off any breakfast item</p>

                    <div className="flex items-center">
                        <p className="mr-5"><strong>-£1.00</strong></p>
                        <button 
                            className="p-1 rounded-md btn-danger"

                        >
                            <svg width="45" height="46" viewBox="0 0 45 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_2731_859)">
                                <path d="M35.625 12.5187L32.9812 9.875L22.5 20.3562L12.0187 9.875L9.375 12.5187L19.8562 23L9.375 33.4812L12.0187 36.125L22.5 25.6437L32.9812 36.125L35.625 33.4812L25.1437 23L35.625 12.5187Z" fill="white"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_2731_859">
                                    <rect width="45" height="45" fill="white" transform="translate(0 0.5)"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Totals;
