import React, { createContext, useContext, useState, ReactNode } from 'react';

import { MenuProduct } from '../types/types';

interface SelectedOptions {
    value: string[],
    type: "area" | "multiple" | "radio",
    quantities: {
        [key: string]: number;
    },
    totals: {
        [key: string]: number;
    }
}

interface ProductContextProps {
    productDetails: MenuProduct;
    selectedOptions: any;
    setSelectedOptions: any;
    enteredOptions: any;
    setEnteredOptions: any;
    selectedCustomizabilityOptions: any;
    setSelectedCustomizabilityOptions: any;
    selectedTextAreaCustomizabilityOptions: any;
    setSelectedTextAreaCustomizabilityOptions: any;
}

const ProductContext = createContext<ProductContextProps | undefined>(undefined);

export const ProductWrapper: React.FC<{ children: ReactNode, productDetails: MenuProduct }> = ({ 
    children, 
    productDetails 
}) => {
    const [selectedOptions, setSelectedOptions] = useState({});

    const [enteredOptions, setEnteredOptions] = useState([]);

    const [selectedCustomizabilityOptions, setSelectedCustomizabilityOptions] = useState([]);

    const [selectedTextAreaCustomizabilityOptions, setSelectedTextAreaCustomizabilityOptions] = useState([]);

    return (
        <ProductContext.Provider
            value={{
                productDetails,
                selectedOptions, 
                setSelectedOptions,
                enteredOptions, 
                setEnteredOptions,
                selectedCustomizabilityOptions,
                setSelectedCustomizabilityOptions,
                selectedTextAreaCustomizabilityOptions,
                setSelectedTextAreaCustomizabilityOptions
            }}
        >
            {children}
        </ProductContext.Provider>
    );
};

export const useProduct = (): ProductContextProps => {
    const context = useContext(ProductContext);
    if (context === undefined) {
        throw new Error('useMenu must be used within a MenuProvider');
    }
    return context;
};