import { FC } from 'react';
import { useMenu } from '../../context/Menu';

import { useGetTheme } from '../../hooks/useGetTheme';
import { MenuCategory } from '../../types/types'; 

export const MenuCategories: FC = () => {
    const theme = useGetTheme();
    const { categories, selectedCategory, handleCategoryChange } = useMenu();

    function isSelected(category: MenuCategory) {
        return category.id === selectedCategory ? true  : false;
    }

    if((categories && !categories.length) || !categories) return null;

    return (
        <div className="flex flex-col mt-[83px] items-start">
            <button
                className="px-7 py-4 text-3xl font-bold w-full text-left"
                style={{
                    backgroundColor: selectedCategory === '' ? theme.secondaryColor : '',
                    color: selectedCategory === '' ? theme.primaryColor : '',
                }}
                onClick={() => {
                    handleCategoryChange('');
                }}  
            >
                All
            </button>

            {categories?.map((category) => {
                return (
                    <button
                        className="px-7 py-4 text-3xl font-bold w-full text-left"
                        style={{
                            backgroundColor: `${isSelected(category) ? theme.secondaryColor : ''}`,
                            color: `${isSelected(category) ? theme.primaryColor : ''}`,
                        }}
                        onClick={() => {
                            handleCategoryChange(category.id)
                        }}
                        key={`category-menu-${category.id}`}
                    >
                        {category.name}
                    </button>
                )
            })}
            
        </div>
    );
};

export default MenuCategories;
