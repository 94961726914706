export const getCartLocalStorage = () => {
    return localStorage.getItem('cart');
}

export const updateCartLocalStorage = (cart: string) => {
    localStorage.setItem('cart', cart);
}

// Reset guest cart id
export const resetGuestCartIdInLocalStorage = () => {
    localStorage.removeItem('cart');
}