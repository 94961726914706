import { fetcher } from "./fetcher";
import { ERROR_HANDLING } from '../queries/errorHandling';

function cleanup(data: any) {
    return data.data.stratus21GetAvailableTimeslots;
}

interface TablesPayload {
    storeId: number;
}

export default async function fetchTables(payload: TablesPayload) {
    let fetchTablesQuery = `query {
        stratus21GetAvailableTimeslots(
            storeId: "${payload.storeId}",
            day: "tuesday"
        ) {
            seating {
                label
                number
                row
                seatValue
            }
            ${ERROR_HANDLING}
        }
    }`

    return await fetcher(fetchTablesQuery, cleanup);
}