import { FC } from 'react';

interface ModalInterface {
    show: boolean;
    children: React.ReactNode;
    nopadding?: boolean;
    width?: number;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    onClose?: Function;
}

export const Modal: FC<ModalInterface> = ({ 
    show, 
    children, 
    nopadding, 
    width, 
    setShow,
    onClose
}) => {

    if(!show) return null;
    
    return (
        <>
            <button 
                className='modal-overlay fixed left-0 top-0 w-full h-full z-10'
                onClick={() => {
                    if (onClose) {
                        onClose();
                    }
                    
                    setShow(false);
                }}
            ></button>

            <div 
                className='fixed m-auto z-20 flex justify-center items-center top-0 left-0 h-full w-full pointer-events-none'
            >
                <div 
                    className={`flex flex-col bg-white rounded-lg shadow-xl ${nopadding ? '' : 'px-11 py-8'} w-[720px] modal-height pointer-events-auto`}
                    style={{
                        width: `${width}px`
                    }}
                >
                    {children}
                </div>
            </div>
        </>
    );
};

export default Modal;
