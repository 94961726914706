import { Capacitor, registerPlugin } from "@capacitor/core";
import { FC, useEffect, useState } from "react";
import { useGetTheme } from "../../../hooks/useGetTheme";

interface KioskScannerInterface {
    addListener: Function
}

const scanner = registerPlugin('BarcodeScanner') as KioskScannerInterface;

export const ScanQr: FC = () => {
    const [scannedQR, setScannedQR] = useState<string>('');
    const theme = useGetTheme();
    const isNative = Capacitor.isNativePlatform();

    useEffect(() => {
        if(isNative) {
            scanner.addListener('onScanData', (eventData: string) => {
                setScannedQR(eventData);
            });
        }
    }, [isNative]);

    console.log(scannedQR);

    return (
        <div 
            className="flex p-8 items-center mb-4"
            style={{
                backgroundColor: theme?.secondaryColor
            }}
        >
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-6">
                <path d="M0 21.3333H21.3333V0H0V21.3333ZM5.33333 5.33333H16V16H5.33333V5.33333Z" fill="#020617"/>
                <path d="M0 48H21.3333V26.6666H0V48ZM5.33333 32H16V42.6666H5.33333V32Z" fill="#020617"/>
                <path d="M26.667 0V21.3333H48.0003V0H26.667ZM42.667 16H32.0003V5.33333H42.667V16Z" fill="#020617"/>
                <path d="M48.0003 42.6666H42.667V48H48.0003V42.6666Z" fill="#020617"/>
                <path d="M32.0003 26.6666H26.667V32H32.0003V26.6666Z" fill="#020617"/>
                <path d="M37.3333 32H32V37.3333H37.3333V32Z" fill="#020617"/>
                <path d="M32.0003 37.3334H26.667V42.6667H32.0003V37.3334Z" fill="#020617"/>
                <path d="M37.3333 42.6666H32V48H37.3333V42.6666Z" fill="#020617"/>
                <path d="M42.6663 37.3334H37.333V42.6667H42.6663V37.3334Z" fill="#020617"/>
                <path d="M42.6663 26.6666H37.333V32H42.6663V26.6666Z" fill="#020617"/>
                <path d="M48.0003 32H42.667V37.3333H48.0003V32Z" fill="#020617"/>
            </svg>

            <p className="text-[30px]"><strong>Got a loyalty card? Scan it now</strong> to claim your <strong>vouchers</strong> and <strong>points!</strong></p>
        </div>
    )
}

export default ScanQr;