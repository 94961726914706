// React
import { useEffect, useCallback, FC, useRef, useState } from "react";
import { useProduct } from "../../context/Product";

interface ProductTextAreaInterface {
    uid: any;
}

export const ProductTextArea: FC<ProductTextAreaInterface> = ({
    uid
}) => {
    
    const {
        selectedTextAreaCustomizabilityOptions,
        enteredOptions,
        setEnteredOptions
    } = useProduct();

    const [hasPopulatedValues, setHasPopulatedValues] = useState(0);

    /**
     * Update the entered options
     * @param {Object} textarea
     * * entered_options: [{
        uid: "Y3VzdG9tLW9wdGlvbi8x"
        value: "Congrats, Julie!"
    }]
     */
    const updateEnteredOptions = useCallback((value: any) => {
        // Check to see whether the option exists in the array of options & update accordingly
        if (enteredOptions.filter((option: { uid: any; }) => option.uid === uid).length) {

            // Remove from array
            const newState = enteredOptions.filter((option: { uid: any; }) => option.uid !== uid);

            if (value) {
                newState.push({
                    uid: uid,
                    value: value
                });
            }

            setEnteredOptions(newState);
        } else {
            let newOptions = enteredOptions;
            // Add to array
            newOptions.push({
                uid: uid,
                value: value
            });

            setEnteredOptions(newOptions);
        }
    }, [enteredOptions, setEnteredOptions, uid]);

    const [defaultValue, setDefaultValue] = useState('');

    // Pre populate textarea
    useEffect(() => {
        // Find textarea in editdata, for edit screen
        const foundValue = selectedTextAreaCustomizabilityOptions.filter((option: { uid: any; }) => option.uid === uid);

        if (foundValue.length && !hasPopulatedValues) {
            setHasPopulatedValues(1);
            setDefaultValue(foundValue[0].value);
            updateEnteredOptions(foundValue[0].value);
        }
    }, [hasPopulatedValues, uid, selectedTextAreaCustomizabilityOptions, defaultValue, updateEnteredOptions]);

    const textAreaRef = useRef(null);

    return (
        <textarea
            className="w-full border border-gray-200 h-[120px] outline-none p-5 resize-none"
            value={defaultValue}
            onChange={((e) => {
                setDefaultValue(e.target.value);
                updateEnteredOptions(e.target.value);
            })}
            ref={textAreaRef}
        >
        </textarea>
    )
}

export default ProductTextArea;