import { FC } from 'react';
import { useGetTheme } from '../../hooks/useGetTheme';
import { useMenu } from '../../context/Menu';

export const MenuSearch: FC = () => {
    const theme = useGetTheme();
    const { searchQuery, handleSearchQueryChange } = useMenu();

    return (
        <div className="search w-full relative mb-6">
            <input 
                type="text" 
                name="search" 
                placeholder="Search menu..." 
                value={searchQuery}
                onChange={(e) => {
                    handleSearchQueryChange(e.target.value);
                }}
                className="rounded-lg border border-inputGray px-7 py-4 w-full" 
                style={{
                    color: theme.primaryColor
                }}
            />
            
            {searchQuery.length ? (
                <button
                    className="absolute right-20 top-4"
                    style={{
                        color: theme.primaryColor
                    }}
                    onClick={() => {
                        handleSearchQueryChange('');
                    }}
                >
                    <svg 
                        width="20" 
                        height="20" 
                        viewBox="0 0 20 20" 
                        fill="none" 
                        xmlns="http://www.w3.org/2000/svg"
                        className='w-7 h-7'
                    >
                        <path d="M2.53317 19.3333L0.666504 17.4666L8.13317 9.99996L0.666504 2.53329L2.53317 0.666626L9.99984 8.13329L17.4665 0.666626L19.3332 2.53329L11.8665 9.99996L19.3332 17.4666L17.4665 19.3333L9.99984 11.8666L2.53317 19.3333Z" fill="currentColor"/>
                    </svg>
                </button>
            ) : null}

            <button
                className="absolute right-5 top-3"
                style={{
                    color: theme.primaryColor
                }}
            >
                <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M25.0143 22.0126H23.4334L22.8731 21.4723C24.8342 19.191 26.0149 16.2293 26.0149 13.0074C26.0149 5.82333 20.1915 0 13.0074 0C5.82333 0 0 5.82333 0 13.0074C0 20.1915 5.82333 26.0149 13.0074 26.0149C16.2293 26.0149 19.191 24.8342 21.4723 22.8731L22.0126 23.4334V25.0143L32.0183 35L35 32.0183L25.0143 22.0126ZM13.0074 22.0126C8.02458 22.0126 4.00229 17.9903 4.00229 13.0074C4.00229 8.02458 8.02458 4.00229 13.0074 4.00229C17.9903 4.00229 22.0126 8.02458 22.0126 13.0074C22.0126 17.9903 17.9903 22.0126 13.0074 22.0126Z" fill="currentColor"/>
                </svg>
            </button>
        </div>
    );
};

export default MenuSearch;
