
import { registerPlugin } from "@capacitor/core";

const Light : any = registerPlugin('Light');

const useLight = () => {
    
    const connectToLamp = async () => {
        try {
            await Light.connectService();
        } catch (error) {
            console.error('Error connecting to service:', error);
        }
    };

    const handleControlLamp = async (lampId: string) => {
        try {
            // Ensure that the service is connected before calling controlLamp()
            await connectToLamp();

            // Call controlLamp method
            await Light.controlLamp({
                status: 0,
                lamp: lampId
            });
        } catch (error) {
            console.error('Error controlling lamp handleControlLamp:', error);
        }
    };

    const handleStartBlinkingLamp = async (lamp: string) => {
        // 0 is on, 1 is off
        try {
            await connectToLamp();

            // Call controlLamp method
            await Light.startBlinking({ lamp: lamp });
        } catch (error) {
            console.error(error);
            console.error('Error controlling lamp startBlinking:', error);
        }
    };

    const handleStopBlinkingLamp = async (lamp: string) => {
        try {
            await Light.stopBlinking({ lamp: lamp });
            // await Light.controlLamp({
            //     status: 0,
            //     lamp: "Led-2"
            // });
        } catch (error) {
            console.error(error);
            console.error('Error controlling lamp stopBlinking:', error);
        }
    };

    return {
        handleControlLamp,
        handleStartBlinkingLamp,
        handleStopBlinkingLamp
    };
};

export default useLight;
