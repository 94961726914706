import { FC } from "react";
import Logo from "./Logo";

export const Hero: FC<{alt?: boolean}> = ({ alt }) => {
    return (
        <div
            className="w-full h-[470px] relative"
            style={{
                backgroundImage: "url(hero-image.jpg)",
                backgroundSize: "cover"
            }}
        >
            <Logo />
            <img src={alt ? 'hero-curve-alt.png' : 'hero-curve.png'} alt="Curve" className="absolute bottom-0 "/>
        </div>
    )
}

export default Hero;