import { useQuery } from "@tanstack/react-query";
import fetchTables from "../api/fetchTables";
import { TableInterface } from "../types/types";

export const useGetTables = () => {
    const payload = {
        storeId: 2
    };
    
    const { data: tables } = useQuery<TableInterface>({
        queryKey: ["tables", payload],
        queryFn: () => fetchTables(payload),
        onSuccess: (data) => {
            if(!data?.errorHandling?.success) {
                
            }
    
            // handleResponseErrors(data?.errorHandling);
        },
        onError: (err) => {
            // handleCatchErrors(err, "fetchCustomer api failed");
        }
    });

    return tables ?? {
        seating: []
    };
};
