import React, { createContext, useContext, useState, ReactNode } from 'react';

// Define the shape of your context
interface AppContextType {
    requestId: string | null;
    setRequestId: (id: string) => void;
    globalError: string | null;
    setGlobalError: (error: string) => void;
    clearGlobalError: () => void;
}

// Create the AppContext with default values
const AppContext = createContext<AppContextType>({
    requestId: null,
    setRequestId: () => {},
    globalError: null,
    setGlobalError: () => {},
    clearGlobalError: () => {},
});

// Custom hook to use the AppContext
export const useAppContext = () => useContext(AppContext);

// Create a provider component
export const AppProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [requestId, setRequestId] = useState<string | null>(null);
    const [globalError, setGlobalError] = useState<string | null>(null);

    const clearGlobalError = () => setGlobalError(null);

    return (
        <AppContext.Provider 
        value={{ 
            requestId, 
            setRequestId, 
            globalError, 
            setGlobalError, 
            clearGlobalError 
        }}
        >
            {children}
        </AppContext.Provider>
    );
};
