import { FC } from "react";
import ScanQr from "./ScanQr";
import Vouchers from "./Vouchers";
import Points from "./Points";

export const Loyalty: FC = () => {
    // TODO need isLoggedIn flag on cart
    const isLoyaltyUser = false;
    
    return isLoyaltyUser ? (
        <>
            <Vouchers />
            <Points />
        </>
    ) : <ScanQr />;
}

export default Loyalty;