import { FC, useEffect, useState } from 'react';
import { useProduct } from '../../context/Product';
import { PurchaseOptions, PurchaseOptionValue } from '../../types/types';

// Utilities
import currencyFormatter from '../../utilities/currencyFormatter';
import ProductCheckbox from './ProductCheckbox';

// Components
import ProductRadio from './ProductRadio';
import ProductTextArea from './ProductTextArea';

interface ProductOptionInterface {
    productOption: PurchaseOptions
}

export const ProductOption: FC<ProductOptionInterface> = ({productOption}) => {

    const {
        selectedOptions
    } = useProduct();
 
    const [groupTotal, setGroupTotal] = useState(0);
    const [groupQuantity, setGroupQuantity] = useState(0);

    console.log(selectedOptions, "selected options");

    // Calculate Totals
    useEffect(() => {
        if (selectedOptions[productOption.uid]?.totals && Object.keys(selectedOptions[productOption.uid]?.totals).length) {
            let groupPrice = 0;

            for (const [, value] of Object.entries(selectedOptions[productOption.uid].totals)) {
                groupPrice += Number(value);
            }

            setGroupTotal(groupPrice);

            // Count number of checkboxes selected
            let groupQuantityCount = 0;

            for (const [, value] of Object.entries(selectedOptions[productOption.uid].quantities)) {
                groupQuantityCount += Number(value);
            }

            setGroupQuantity(groupQuantityCount);
        }
    }, [productOption.uid, selectedOptions]);

    const defaultSelectedOption = {
        value: [],
        type: productOption.type,
        quantities: {},
        totals: {}
    };

    const selectedOption = selectedOptions[productOption.uid] !== undefined ? selectedOptions[productOption.uid] : defaultSelectedOption;

    return (
        <div key={`${productOption.label}-${productOption.id}`}>
            <div className="flex justify-between mt-5">
                {/* Group name */}
                <p className='text-2xl font-bold mb-4'>{productOption.label} {productOption.required ? `*` : null}</p>

                {/* Total for this group, only output for checkbox */}
                {productOption.type === "multiple" && groupTotal ? (
                    <p className="text-xl font-bold m-0">
                        {groupTotal > 0 ? `+ ${currencyFormatter.format(groupTotal)}` : null}
                    </p>
                ) : null}
            </div>

            {productOption?.maxLimit !== 0 && productOption.type === "multiple" && (
                <div className={`flex mb-3 text-lg`}>
                    Please note, you can only select {productOption?.maxLimit} {productOption.label.toLowerCase()} in total.
                    <div className="ml-auto">{groupQuantity}/{productOption?.maxLimit}</div>
                </div>
            )}

            {/* Loop through available options, output relevant input */}
            {productOption.values && productOption.values.length ? (
                <>
                {
                    productOption.values.map((choice: PurchaseOptionValue) => {
                        // if (!selectedOptions[productOption.uid]) {
                        //     return null;
                        // }
        
                        return (
                            <div
                                key={`${choice.uid}`}
                            >
                                {/* Radio */}
                                {productOption.type === "radio" ? (
                                    <ProductRadio
                                        radioData={choice}
                                        productOption={productOption}
                                        selectedOption={selectedOption}
                                    />
                                ) : null}
        
                                {/* Checkboxes */}
                                {productOption.type === "multiple" ? (
                                    <ProductCheckbox
                                        checkData={choice}
                                        maxOptionLimit={productOption?.maxLimit}
                                        selectedOption={selectedOption}
                                        groupQuantity={groupQuantity}
                                        productOption={productOption}
                                    />
                                ) : null}
                            </div>
                        );
                    })
                }
                </>
            ) : null}

            {/* Textarea */}
            {productOption.type === "area" ? (
                <ProductTextArea
                    uid={productOption.uid}
                />
            ) : null}
        </div>
    );
};

export default ProductOption;
