import { useQuery } from "@tanstack/react-query";
import fetchCart from "../api/fetchCart";
import { CartInterface } from "../types/types";

export const useGetCart = () => {
    const { data: cart } = useQuery<CartInterface>({
        staleTime: 1000 * 60,
        queryKey: ["cart"],
        queryFn: fetchCart,
        onSuccess: (data) => {
            if(!data?.errorHandling?.success) {
                // resetGuestCartIdInLocalStorage();
            }
    
            // handleResponseErrors(data?.errorHandling);
        },
        onError: (err) => {
            // handleCatchErrors(err, "fetchCustomer api failed");
        }
    });

    return cart
};
