import { cartInfo } from '../queries/cart';
import { ERROR_HANDLING } from '../queries/errorHandling';
import { fetcher } from './fetcher';

// Clean up server response (It was returning data.data.cart.cart etc)
function responseCleanup(data: any) {
    return data.data.addProductsToCart;
}

interface AddToCartApiInterface {
    cartID: string,
    cartItems: Array<{
        quantity: number;
        sku: string;
        selected_options?: any;
        entered_options?: any;
    }>;
}

export async function addToCart(payload : AddToCartApiInterface) {
    const cartItemsString = JSON.stringify(payload.cartItems)
    .replace(/"([^"]+)":/g, '$1:'); 
    
    return await fetcher(
        `
            mutation AddItemToCart {
                addProductsToCart(
                    cartId: "${payload.cartID}",
                    cartItems: ${cartItemsString}
                ) {
                    ${cartInfo}
                    user_errors {
                        code
                        message
                    }
                    ${ERROR_HANDLING}
                }
            }
        `,
        responseCleanup
    );
}