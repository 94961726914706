import { DojoConfig } from "../types/types";

export default async function makeDojoPayment(
    amount: number, 
    dojoConfig: DojoConfig, 
    headers: Headers
) {
    if (!dojoConfig.currentTerminalId) return null;

    console.log("amount", amount);

    // Define the endpoint URL
    const endpoint = `pac/terminals/${dojoConfig.currentTerminalId}/transactions`;

    // Define the payload
    const payload = {
        transactionType: "SALE",
        amount: Math.round(amount * 100),
        currency: "GBP",
        cardholderNotPresent: false,
    };

    // Define the request options
    const requestOptions: RequestInit = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(payload),
    };

    try {
        // Make the fetch request using the fetcher
        const response = await fetch(`${dojoConfig.url}/${endpoint}`, requestOptions);
        const data = await response.json();

        console.log('Response:', data);
        return data;
    } catch (error) {
        console.error('Error making payment:', error);
        return null;
    }
}
