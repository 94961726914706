import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import BackButton from "../components/BackButton";
import HelpButton from "../components/HelpButton";
import CancelButton from "../components/CancelButton";
import RetryButton from "../components/Payment/RetryButton";
import useLight from "../hooks/useLight";
import { useDojo } from "../hooks/useDojo";
import { useAppContext } from "../context/App";

function Payment() {
    const {
        requestId
    } = useAppContext();

    const navigate = useNavigate();

    const {
        handleControlLamp
    } = useLight();

    const {
        transactionStatus
    } = useDojo();

    useEffect(() => {
        handleControlLamp("Led-1");
    }, []);

    console.log("transactionStatus?.transactionResult", transactionStatus?.transactionResult)

    return (
        <div className="px-[75px] py-14 flex flex-col justify-between h-full">
            <div className="flex justify-between mb-8">
                <div>
                    <BackButton 
                        onClick={() => {
                            navigate("/cart");
                        }} 
                    />
                </div>
                
                <div className="flex">
                    {
                        requestId ? <HelpButton /> : null
                    }
                </div>
            </div>
            
            {!requestId ? (
                <div className="text-center mt-auto">
                    <svg width="150" height="150" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg" className="mb-9 mx-auto">
                        <g clipPath="url(#clip0_2172_1602)">
                            <path d="M125 12.5H25C18.125 12.5 12.5625 18.125 12.5625 25L12.5 137.5L37.5 112.5H125C131.875 112.5 137.5 106.875 137.5 100V25C137.5 18.125 131.875 12.5 125 12.5ZM81.25 87.5H68.75V75H81.25V87.5ZM81.25 62.5H68.75V37.5H81.25V62.5Z" fill="#334155"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_2172_1602">
                            <rect width="150" height="150" fill="white"/>
                        </clipPath>
                        </defs>
                    </svg>

                    <p className="text-[50px] font-bold leading-tight text-center mb-14">Payment has failed, please try again or choose another card</p>

                    <div className="flex justify-center">
                        <div className="flex mr-6">
                            <CancelButton />
                        </div>

                        <div className="flex mr-6">
                            <HelpButton />
                        </div>

                        <RetryButton />
                    </div>
                </div>
            ) : (
                <>
                    {transactionStatus?.notifications.includes('REMOVE_CARD') ? (
                        <div className="text-center mt-auto">
                            <svg className="animate-spin mb-9 mx-auto h-20 w-20 text-slate-950" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
        
                            <p className="text-[50px] font-bold leading-tight text-center">Payment Processing...</p>
                        </div>
                    ) : (
                        <div className="text-center mt-auto">
                            <svg width="150" height="150" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg" className="mb-9 mx-auto">
                                <g clipPath="url(#clip0_2153_1215)">
                                    <path d="M125 25H25C18.0625 25 12.5625 30.5625 12.5625 37.5L12.5 112.5C12.5 119.438 18.0625 125 25 125H125C131.938 125 137.5 119.438 137.5 112.5V37.5C137.5 30.5625 131.938 25 125 25ZM125 112.5H25V75H125V112.5ZM125 50H25V37.5H125V50Z" fill="#334155"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_2153_1215">
                                    <rect width="150" height="150" fill="white"/>
                                </clipPath>
                                </defs>
                            </svg>
        
                            <p className="text-[50px] font-bold leading-tight text-center">Please complete payment on the card reader below</p>
                        </div>
                    )}
                </>
            )}
            
            <img src="payment-methods.png" alt="Payment methods" className="mt-auto mx-auto" />
        </div>
    );
}


export default Payment;