import { FC, useState } from 'react';
import { useMenu } from '../../context/Menu';
import { useGetCart } from '../../hooks/useGetCart';
import { useGetTheme } from '../../hooks/useGetTheme';

import { MenuCategory, MenuProduct, CartItem } from '../../types/types';

import ProductModal from '../ProductModal';

export const MenuProductList: FC = () => {
    const { filteredCategories } = useMenu();
    const cart = useGetCart();
    const theme = useGetTheme();

    const [showProductModal, setShowProductModal] = useState(false);
    const [productModalData, setProductModalData] = useState<MenuProduct>();
    
    function hasCategories() {
        return filteredCategories && filteredCategories.length > 0;
    }

    if (!hasCategories()) return null;

    return (
        <>
            <div className="flex flex-col mt-[83px] items-start">
                {filteredCategories.map((category: MenuCategory) => {
                    if (!category.active) return null;

                    return (
                        <div className='mb-8 w-full' key={`category-${category.id}`}>
                            <h2 className="font-bold text-4xl mb-6">{category.name}</h2>

                            {category.products && category.products.length ? (
                                <>
                                    {category.products.map((product: MenuProduct) => {
                                        const productInCart = cart?.cart.items.filter(item => item.product.id === product.id);
                                        let productQuantityInCart = 0;

                                        if(productInCart && productInCart.length) {
                                            productInCart.forEach((product: CartItem) => {
                                                productQuantityInCart += Number(product.quantity);
                                            })
                                        }

                                        return (
                                            <button 
                                                key={product.id}
                                                className="bg-white px-7 py-6 flex justify-between rounded-lg text-left mb-3 w-full"
                                                onClick={() => {
                                                    setShowProductModal(true);
                                                    setProductModalData(product);
                                                }}
                                            >
                                                <div className="flex flex-col pr-5">
                                                    <h3 className="font-bold mb-2 text-2xl leading-none">{product.name}</h3>
                                                    <p className="mb-2 text-base leading-normal">{product.shortDescription}</p>
                                                    <p className="font-bold text-base">{product.priceFormatted}</p>

                                                    <div className="flex mt-2">
                                                        <div className="tag bg-tag text-tag">TODO</div>
                                                    </div>
                                                </div>

                                                <div className="w-[130px] h-[130px] overflow-hidden rounded-lg relative">
                                                    {productInCart && productInCart.length ? (
                                                        <div 
                                                            className='w-10 h-10 absolute text-lg p-5 z-10 flex justify-center items-center right-0 font-bold'
                                                            style={{
                                                                backgroundColor: theme?.secondaryColor,
                                                                color: theme.primaryColor
                                                            }}
                                                        >
                                                            {productQuantityInCart}x
                                                        </div>
                                                    ) : null}

                                                    <img 
                                                        src={product.imageThumbnail} 
                                                        alt={product.name} 
                                                        className="h-[130px] w-auto absolute max-w-fit" 
                                                    />
                                                </div>
                                            </button>
                                        );
                                    })}
                                </>
                            ) : (
                                'No products available within this category'
                            )}
                        </div>
                    )
                })}
            </div>

            {productModalData && productModalData?.name ? (
                <ProductModal
                    productDetails={productModalData}
                    show={showProductModal} 
                    setShow={setShowProductModal} 
                />
            ) : null}
        </>
    );
};

export default MenuProductList;