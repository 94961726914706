import { FC } from "react";
import { useGetTheme } from "../../hooks/useGetTheme";

export const VoucherPopups: FC = () => {
    const theme = useGetTheme();

    // TODO - Need to link up to voucher add modal

    return (
        <div className="absolute bottom-[200px] left-[75px] right-[75px]">
            <div className="rounded-lg flex border border-slate-300 overflow-hidden mx-auto w-full shadow-lg">
                <div 
                    className="px-6 py-5"
                    style={{
                        backgroundColor: theme.secondaryColor
                    }}
                >
                    <svg 
                        width="38" 
                        height="48" 
                        viewBox="0 0 38 48" 
                        fill="none" 
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-10"
                    >
                        <path d="M20.6458 43.75L0.25 23.3541V27.5208C0.25 28.625 0.6875 29.6875 1.47917 30.4583L17.7083 46.6875C19.3333 48.3125 21.9792 48.3125 23.6042 46.6875L36.5417 33.75C38.1667 32.125 38.1667 29.4791 36.5417 27.8541L20.6458 43.75Z" fill="white"/>
                        <path d="M17.7083 36.2708C19.3333 37.8958 21.9792 37.8958 23.6042 36.2708L36.5417 23.3333C38.1667 21.7083 38.1667 19.0625 36.5417 17.4375L20.3125 1.20833C19.5208 0.4375 18.4583 0 17.3542 0H4.41667C2.125 0 0.25 1.875 0.25 4.16667V17.1042C0.25 18.2083 0.6875 19.2708 1.47917 20.0417L17.7083 36.2708ZM9.10417 6.25C10.5417 6.25 11.7083 7.41667 11.7083 8.85417C11.7083 10.2917 10.5417 11.4583 9.10417 11.4583C7.66667 11.4583 6.5 10.2917 6.5 8.85417C6.5 7.41667 7.66667 6.25 9.10417 6.25Z" fill="white"/>
                    </svg>
                </div>

                <div className="px-8 py-4 bg-white w-full flex items-center">
                    <p><strong>£1.00 off any breakfast item</strong> voucher added</p>
                </div>
            </div>

            <div className="rounded-lg flex border border-slate-300 overflow-hidden mx-auto w-full shadow-lg mt-4">
                <div 
                    className="px-6 py-5"
                    style={{
                        backgroundColor: theme.primaryColor
                    }}
                >
                    <svg 
                        width="42" 
                        height="40" 
                        viewBox="0 0 42 40" 
                        fill="none" 
                        xmlns="http://www.w3.org/2000/svg" 
                        className="w-10"
                    >
                        <path d="M21.0003 31.9792L33.8753 39.7501L30.4587 25.1042L41.8337 15.2501L26.8545 13.9792L21.0003 0.166748L15.1462 13.9792L0.166992 15.2501L11.542 25.1042L8.12533 39.7501L21.0003 31.9792Z" fill="white"/>
                    </svg>
                </div>

                <div className="px-8 py-4 bg-white w-full flex items-center">
                    <p><strong>£5.00</strong> of reward points redeemed</p>
                </div>
            </div>
        </div>
    )
};

export default VoucherPopups;
