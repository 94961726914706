import { useNavigate } from "react-router-dom";
import { resetGuestCartIdInLocalStorage } from "../utilities/localStorage";

export const useRestartKiosk = () => {
    const navigate = useNavigate();

    function restartKiosk() {
        resetGuestCartIdInLocalStorage();
        navigate('/start');
    }

    return {
        restartKiosk
    };
};
