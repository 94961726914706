interface ApiResponse {
    errorHandling?: any;
}

export async function fetcher(
    query : string, 
    cleanup : Function
) {

    const response = await fetch(
        process.env.NEXT_PUBLIC_MAGENTO_API_URL ?? 'https://s21preorderbackend.fidelityportal.co.uk/graphql',
        {
            method: "POST",
            body: JSON.stringify({
                query: query,
            }),
            headers: {
                "Content-Type": "application/json"
            }
        }   
    );

    if (!response.ok) {
        // Log the error response to Sentry
        //Sentry.captureException(new Error(`API Error: ${response.status} ${response.statusText}`));
    }

    const jsonResponse = await response.json();

    if (jsonResponse.errors) {
        // If the JSON response contains errors, log them to Sentry
        //Sentry.captureException(new Error(`API Response Error: ${JSON.stringify(jsonResponse.errors)}`));
    }

    // Log Error handling
    if (jsonResponse.data) {
        Object.entries(jsonResponse.data as Record<string, ApiResponse>).forEach(([key, value]) => {
            if (value && value.errorHandling && !value.errorHandling.success) {
                value.errorHandling.message.forEach((message: any) => {
                    const errorMessage = message.message;
                    // const errorType = message.type;
                    
                    // Log each error message with the API request name
                    const allowedErrorMessages = [
                        'The request is allowed for logged in customer',
                        'The account sign-in was incorrect or your account is disabled',
                        'Minimum of different classes of characters',
                        'Verify the code and try again',
                        'Cannot reset the customers password',
                        'A customer with the same email address already exists',
                        'The current user cannot perform operations'
                    ];

                    let isAllowedMessage = allowedErrorMessages.some((message) => errorMessage.includes(message));

                    if(!isAllowedMessage) {
                        //Sentry.captureException(new Error(`API Error Handling in ${key}: ${errorType} - ${errorMessage}`));
                    }
                });
            }
        });
    }

    return cleanup(jsonResponse);
}